import GetStarted from './routes/GetStarted'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { auth, db } from './firebase.js'
import { doc, getDoc } from 'firebase/firestore'
import Layout from './components/Layout.js'
import ChatPage from './routes/ChatPage.js'
import GameLobby from './routes/GameLobby.js'
import AdminPage from './routes/AdminPage.js'
import UpdatePrompts from './routes/UpdatePrompts.js'
import ListPrompts from './routes/ListPrompts.js'
import NotFoundPage from './routes/NotFoundPage.js'

function App () {
  const [loading, setLoading] = useState(true)
  // Player State
  const [user, setUser] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [gameId, setGameId] = useState(null)
  const [playerName, setPlayerName] = useState(null)
  const [playerFaction, setplayerFaction] = useState(null)
  const [playerStory, setPlayerStory] = useState(null)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        authUser.getIdTokenResult().then((idTokenResult) => {
          authUser.admin = idTokenResult.claims.admin
        })
        // If user is authenticated, set the user state
        setUser(authUser)
        // check if user is Playing active game
        fetchPlayerGameState(authUser.uid)
      } else {
        // If user is not authenticated, set user state to null
        setUser(null)
        setLoading(false)
      }
    })

    // Utility function to fetch document from Firestore
    const fetchDocument = async (docRef) => {
      try {
        const docSnap = await getDoc(docRef)
        if (!docSnap.exists()) {
          console.error('No such document!')
          return null
        }
        return docSnap.data()
      } catch (error) {
        console.error('Error fetching document:', error)
        throw error // Rethrowing the error to be handled by the caller
      }
    }
    // queries Game that is reference in player doc and checks if Game is active
    const fetchPlayerGameState = async (userId) => {
      setLoading(true)
      try {
        const userDocRef = doc(db, 'users', userId)
        const userData = await fetchDocument(userDocRef)
        setPlayerName(userData.name)
        if (userData.playerRef) {
          const playerData = await fetchDocument(doc(db, userData.playerRef.path))
          setplayerFaction(playerData.faction)
          setPlayerStory(playerData.story)
        }
        if (userData.gameRef) {
          setGameId(userData.gameRef.split('/').slice(-1)[0])
          const gameData = await fetchDocument(doc(db, userData.gameRef))
          if (gameData) {
            const isPlaying = Boolean(gameData.active) // Ensure the active flag is a boolean
            setIsPlaying(isPlaying)
          }
        // if GameRef is null
        } if (!userData.gameRef) {
          setIsPlaying(false)
        }
      } catch (error) {
        console.error('Error fetching player game state:', error)
      }
      // wait for Game Status if User logged in
      setLoading(false)
    }
    // Clean up the subscription when the component unmounts
    return () => unsubscribe()
  }, [])

  if (loading) {
    // Render a loading indicator while waiting for auth state
    return <div>Loading...</div>
  }
  if (user) {
    return (
      <Router>
        <div className="App">
          <Layout playerName={playerName} playerFaction={playerFaction} playerStory={playerStory} gameId={gameId} isPlaying={isPlaying}>
            <Routes>
              <Route path="/" element={isPlaying ? <ChatPage gameRef={gameId} playerName={playerName} playerFaction={playerFaction} setIsPlaying={setIsPlaying} /> : <GameLobby setIsPlaying={setIsPlaying} setGameId={setGameId} />} />
              <Route path="/admin" element={isPlaying ? <Navigate to="/" replace /> : <AdminPage></AdminPage>}></Route>
              <Route path="/update" element={isPlaying ? <Navigate to="/" replace /> : <UpdatePrompts></UpdatePrompts>}></Route>
              <Route path="/list" element={isPlaying ? <Navigate to="/" replace /> : <ListPrompts></ListPrompts>} ></Route>
              <Route path="/*" element={ <NotFoundPage></NotFoundPage>} ></Route>
            </Routes>
          </Layout>
        </div>
      </Router>
    )
  }

  return (
    <Router>
      <div className="App">
          <Routes>
            <Route path="/" element={<GetStarted/>} />
            <Route path="/*" element={<Navigate to="/" replace />} />
          </Routes>
      </div>
    </Router>
  )
}

export default App
