import { TextField, Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { PropTypes } from 'prop-types'

const TextInput = ({ handleSubmit, buttonLabel, inputLabel }) => {
  const [input, setInput] = useState('')

  const handleClick = () => {
    handleSubmit(input)
    setInput('')
  }

  return (
    <>
    <Box sx={{ display: 'flex' }}>
      <TextField variant='outlined' sx={{ flexGrow: 1, mr: '5px' }} label={inputLabel} value={input} onChange={(e) => { setInput(e.target.value) }} />
      <Button variant='contained' color='primary' onClick={handleClick}> {buttonLabel}</Button>
    </Box>
    </>
  )
}

TextInput.propTypes = {
  handleSubmit: PropTypes.func,
  buttonLabel: PropTypes.string,
  inputLabel: PropTypes.string
}

export default TextInput
