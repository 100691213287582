import React from 'react'
import { ToggleButtonGroup, ToggleButton, Box, LinearProgress, Tooltip, Typography } from '@mui/material'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import CampaignIcon from '@mui/icons-material/Campaign'
import ProgessTimer from './ProgressTimer'
import PropTypes from 'prop-types'

const ToggleButtons = ({ selected, countdownActive, actionTimer, handleSelection, handleCountdownEnd, isTooltipOpen }) => {
  return (
    <Tooltip open={isTooltipOpen} title={<Typography variant='body2'> Please choose whether to ask a question or initiate an action first. </Typography>} placement="top" arrow>
      <Box sx={{ mb: '5px' }}>
        <ToggleButtonGroup
          value={selected}
          exclusive
          onChange={handleSelection}
          aria-label="toggle buttons"
        >
          <ToggleButton value="question" aria-label="question-toggle" color='warning'>
            <QuestionMarkIcon/>
          </ToggleButton>
          <ToggleButton value="action" aria-label="action-toggle" disabled={countdownActive} color='warning'
          >
            <CampaignIcon/>
          </ToggleButton>
        </ToggleButtonGroup>
        <Box sx={{ mt: 0.5 }}>
        {countdownActive ? <ProgessTimer initialCount={actionTimer} onCountdownEnd={handleCountdownEnd} ></ProgessTimer> : <LinearProgress variant="determinate" value={100} />}
        </Box>
      </Box>
    </Tooltip>
  )
}

ToggleButtons.propTypes = {
  selected: PropTypes.string.isRequired,
  countdownActive: PropTypes.bool.isRequired,
  isTooltipOpen: PropTypes.bool.isRequired,
  actionTimer: PropTypes.number.isRequired,
  handleSelection: PropTypes.func.isRequired,
  handleCountdownEnd: PropTypes.func.isRequired
}

export default ToggleButtons
