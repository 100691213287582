import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Divider, Badge, Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { PropTypes } from 'prop-types'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

function DialogSlide ({ numberOfNotifications, children }) {
  const styles = {
    button: {
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translate(0, -50%)',
      minWidth: '48px', // Minimum width of the button
      width: '48px', // Width of the button, you can adjust as needed
      height: '80px', // Height of the button, you can adjust as needed
      backgroundColor: 'transparent', // Button background color
      borderRadius: '4px', // Button border radius
      zIndex: 2,
      '&:hover': {
        backgroundColor: '#f0f0f0' // Button hover color
      }
    },
    dialogPaper: {
      width: '80%',
      height: '100vh', // Use 100vh for full viewport height
      maxWidth: 'none',
      overflow: 'hidden', // Hide the scrollbar
      margin: 0, // Remove margin
      maxHeight: 'none',
      top: '0',
      left: '0',
      position: 'absolute',
      transform: 'none',
      borderRadius: '0px 3px 3px 0px'
    }
  }
  const [open, setOpen] = useState(false)
  const [unreadCount, setUnreadCount] = useState(0)

  useEffect(() => {
    // Check if there's a stored count in localStorage
    setTimeout(1000)
    const storedCount = localStorage.getItem('readCount')
    setUnreadCount(numberOfNotifications - storedCount)
  }, [numberOfNotifications])

  const handleClickOpen = () => {
    setOpen(true)
    setUnreadCount(0)
    localStorage.setItem('readCount', numberOfNotifications)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button onClick={handleClickOpen} sx={styles.button}>
      <Badge badgeContent={unreadCount} color="primary">
        <NavigateNextIcon sx={styles.icon} />
      </Badge>
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: styles.dialogPaper
        }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pr: 1
        }}
            >
        <DialogTitle>{'News Feed'}</DialogTitle>
        {<IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
            }
        </Box>
        <Divider></Divider>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    </>
  )
}

DialogSlide.propTypes = {
  children: PropTypes.node.isRequired,
  numberOfNotifications: PropTypes.number.isRequired
}

export default DialogSlide
