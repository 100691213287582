import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
// MUI components
import { useTheme, useMediaQuery, Container, Box, Typography, List } from '@mui/material'
// firebase imports
import { db, auth } from '../firebase.js'
import { collection, onSnapshot, query, where, doc, updateDoc } from 'firebase/firestore'
import GameListItem from '../components/GameListItem.js'

function GameLobby (props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const pageStyle = {
    page: { height: isMobile ? 'calc(100vh - 56px)' : 'calc(100vh - 64px)' },
    headerContainer: { maxWidth: '740px', m: 'auto', height: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' },
    listContainer: { height: isMobile ? '70%' : '60%', maxWidth: '740px', m: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }
  }
  const [games, setGames] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const q = query(collection(db, 'games'), where('active', '==', true))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const gamesDocs = []
      querySnapshot.forEach(doc => {
        gamesDocs.push({
          id: doc.id,
          ...doc.data()
        })
      })
      setGames(gamesDocs)
    })
    return () => unsubscribe()
  }, [])

  const JoinGame = async (gameId) => {
    try {
      const userId = auth.currentUser.uid
      const docRef = doc(db, 'users', userId)
      const gameRef = `/games/${gameId}`

      await updateDoc(docRef, {
        gameRef
      })
      localStorage.removeItem('readCount')
      props.setIsPlaying(true)
      props.setGameId(gameId)
    } catch (error) {
      console.error('Error joining the game:', error)
    }
  }

  const observeGame = (gameId) => {
    navigate('/admin', { state: { gameId } })
  }

  // determine condtional buttons for Admin
  const buttonLabel = auth.currentUser?.admin ? 'Observe' : 'Join 🔗'
  const handleSubmit = auth.currentUser?.admin ? observeGame : JoinGame

  return (
    <Container sx={pageStyle.page}>
      <Box sx={pageStyle.headerContainer}>
        <Typography variant='h2' > Lobby </Typography>
      </Box>
      <Box sx={pageStyle.listContainer}>
        <List sx={{ width: '100%' }}>
          {games.map((game, index) => (
            <GameListItem key={index} uniqueKey={index} gameId={game.id} gameName={game.name} createdAt={game.createdAt} buttonLabel={buttonLabel} Button handleSubmit={handleSubmit}></GameListItem>
          ))}
        </List>

      </Box>
    </Container>
  )
}

GameLobby.propTypes = {
  setIsPlaying: PropTypes.func.isRequired,
  setGameId: PropTypes.func.isRequired
}

export default GameLobby
