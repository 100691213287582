import React from 'react'
import PropTypes from 'prop-types'
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Avatar, Link, Typography } from '@mui/material'
import newsLogo from '..//assets/images/newsLogo.png'

function UnflodableFeedListItem ({ uniqueId, headline, content, createdAt, isOpen, toggleOpen }) {
  const Header = () => {
    return (
  <>
  <Typography
  sx={{ display: 'inline', fontWeight: 'bold' }}
  component="span"
  variant="body1"
  color="text.primary"
  >
  {headline || 'Breaking News' }
  </Typography>
    {' — '}
    <Typography
  sx={{ display: 'inline' }}
  component="span"
  variant="body2"
  color="text.secondary"
  >
  {`${createdAt?.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
  </Typography>
  </>
    )
  }

  return (
    <React.Fragment key={uniqueId}>
    <ListItem alignItems="flex-start" onClick={() => toggleOpen(uniqueId)}>
      <ListItemIcon>
        <Avatar sx={{ objectFit: 'scale-down' }} { ...{ alt: 'News Logo', src: newsLogo } } />
      </ListItemIcon>
        <ListItemText primary={ <Header></Header> } secondary={ (isOpen
          ? ''
          : <Link href="#" underline="hover">
            {'show more'}
          </Link>
           )}/>
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem>
                <ListItemText primary={<Typography variant='body1'> {content} </Typography>} />
              </ListItem>
            </List>
          </Collapse>
    </React.Fragment>
  )
}

UnflodableFeedListItem.propTypes = {
  uniqueId: PropTypes.number.isRequired,
  headline: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  createdAt: PropTypes.object
}

export default UnflodableFeedListItem
