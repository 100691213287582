import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
// import Navbar from '../components/Navbar'
import PropTypes from 'prop-types'
import Appbar from '../components/Appbar'
import { Button, Box, Container, useTheme, useMediaQuery } from '@mui/material'
import LoginModal from '../components/modals/LoginModal'

const GetStarted = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const pageStyle = {
    background: {
      paddingTop: isMobile ? '56px' : '64px',
      bgcolor: 'rgba(229, 229, 247, 0.2)', // Background color with reduced opacity
      backgroundImage: 'repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 40px ), repeating-linear-gradient( #459EB955, #459EB9 )'
    },
    page: { height: isMobile ? 'calc(100vh - 56px)' : 'calc(100vh - 64px)', paddingTop: isMobile ? '56px' : '64px' },
    heroContainer: { maxWidth: '740px', m: 'auto', mt: '3em', dispaly: 'flex', alignItems: 'flex-start' }
  }
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

  const handleClick = () => {
    setIsLoginModalOpen(true)
  }
  const closeModals = () => {
    setIsLoginModalOpen(false)
  }

  return (
    <div style={pageStyle.background}>
      <Appbar/>
    <Container sx={pageStyle.page}>
    <Box sx={pageStyle.heroContainer}>
      <Typography variant="h2" component="div" gutterBottom>
        Welcome to the Peace Game
      </Typography>
      <Typography variant="h3" gutterBottom>
      </Typography>
      <Typography variant="h4" gutterBottom>
        Sikosia, A Country In Unrest. Is Peace Possible?
      </Typography>
      <Button variant='contained' size='large' onClick={handleClick} >Log In</Button>
    </Box>
    </Container>
    <LoginModal isOpen={isLoginModalOpen} handleClose={closeModals}> </LoginModal>
    </div>
  )
}

GetStarted.propTypes = {
  user: PropTypes.object
}

export default GetStarted
