import React from 'react'
import { Box, Modal, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { PropTypes } from 'prop-types'

const BasicModal = ({ isOpen, handleClose, children, title, footerChildren }) => {
  if (!isOpen) return null

  const handleBackdropClick = (event) => {
    if (handleClose === null) {
      return
    }
    if (event.target === event.currentTarget) {
      handleClose()
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleBackdropClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: { xs: '85%', sm: 400, lg: 800 },
          maxWidth: '80%',
          maxHeight: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            borderBottom: 1,
            borderColor: 'divider',
            borderRadius: '10px 10px 0 0' // Apply rounded corners to the top
          }}
        >
          <Typography variant='h5'>
           <Box sx={{ fontWeight: 'medium', mb: 1 }}>{title}</Box>
          </Typography>
          {handleClose && <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
            }
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 100px)', // Adjust this value to ensure the modal content does not exceed the viewport height
            flexGrow: 1,
            p: 2
          }}
        >
          {children} {/* Scrollable content area */}
        </Box>
        {footerChildren && <Box sx={{ p: 1 }}>
            {footerChildren}
        </Box>}
      </Box>
    </Modal>
  )
}

BasicModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  children: PropTypes.node,
  footerChildren: PropTypes.node,
  title: PropTypes.string
}

export default BasicModal
