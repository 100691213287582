import React, { useEffect, useState, useRef } from 'react'
import { useTheme, useMediaQuery, Container, Box, List, Typography, Divider, ListItemText, Button, CircularProgress } from '@mui/material'
import { db } from '../firebase.js'
import { collection, query, where, getDocs, getDoc, doc, updateDoc, orderBy, limit, onSnapshot } from 'firebase/firestore'
import { useLocation } from 'react-router-dom'
import ChatMessage from '../components/ChatMessage'
import BasicModal from '../components/modals/BasicModal.js'

function AdminPage () {
  // Themeing
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const pageStyle = {
    page: { height: matches ? 'calc(100vh - 56px)' : 'calc(100vh - 64px)', display: 'flex', flexDirection: 'row', justifyContent: 'center' },
    infoContainer: { maxWidth: '820px', width: '22%', height: '100%', display: 'flex', flexDirection: 'column', mr: '3px' },
    messagesContainer: { maxWidth: '820px', width: '78%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }
  }

  const location = useLocation()
  const gameId = location.state?.gameId
  const [messages, setMessages] = useState([])
  const [negotiationOutcomes, setnegotiationOutcomes] = useState([])
  const [recentNewsDigests, setRecentNewsDigests] = useState([])
  const [gameObj, setGameObj] = useState({})
  const [isLocked, setIsLocked] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [newsDigest, setNewsDigest] = useState('')
  const [isNewsDigestModalOpen, setNewsDigestModalOpen] = useState(false)
  const [isShowNewsDigestModalOpen, setShowNewsDigestModalOpen] = useState(false)
  const [isNegotiationModalOpen, setNegotiationModalOpen] = useState(false)

  const messagesEndRef = useRef()

  useEffect(() => {
    const messagesQuery = query(
      collection(db, 'messages'),
      where('gameId', '==', gameId)
    )
    const messagesUnsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
      const messagesDocs = []
      querySnapshot.forEach((doc) => {
        messagesDocs.push({
          ...doc.data()
        })
      })
      messagesDocs.sort((a, b) => a.createdAt - b.createdAt)
      setMessages(messagesDocs)
    })
    const fetchGame = async () => {
      const gameSnapshot = await getDoc(doc(db, 'games', gameId))
      setGameObj(gameSnapshot.data())
      setIsLocked(gameSnapshot.data().isLocked)
    }
    fetchGame()
    // clean up
    return () => {
      messagesUnsubscribe()
    }
  }, [])

  // scrolls to bottom after render
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant' })
  }, [gameObj])

  const uniquePlayers = Array.from(messages.reduce((acc, item) => {
    acc.add(item.playerName)
    return acc
  }, new Set()))

  const openNewsDigestModal = async () => {
    const fetchNewsDigest = async () => {
      const gamesColRef = collection(db, 'newsDigest')
      const q = query(gamesColRef,
        where('gameId', '==', gameId),
        orderBy('createdAt', 'desc'),
        limit(1))
      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        // Assuming only one document is returned due to 'limit(1)'
        const newsDigestDoc = querySnapshot.docs[0]
        setNewsDigest(newsDigestDoc.data().content)
      } else {
        // Handle the case where no documents are found
        console.log('No matching documents.')
        // Optionally, reset the newsDigest or set it to a default value
        // setNewsDigest(defaultValueOrEmpty);
      }
    }
    await fetchNewsDigest()
    setNewsDigestModalOpen(true)
  }

  const lockUserScreens = async () => {
    try {
      const docRef = doc(db, 'games', gameId)
      await updateDoc(docRef, {
        isLocked: true
      })
      setIsLocked(true)
      await updateDoc(docRef, {
        isLocked: false
      })
    } catch (error) {
      console.error('Error creating News Digest:', error)
    }
  }

  const openNegotiationModal = async () => {
    const fetchNegotianOutcome = async () => {
      const negColRef = collection(db, 'negotiationOutcome')
      const q = query(negColRef, where('gameId', '==', gameId))
      const negSnap = await getDocs(q)
      const sortedOutcomes = negSnap.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .sort((a, b) => a.createdAt - b.createdAt)

      setnegotiationOutcomes(sortedOutcomes)
    }
    await fetchNegotianOutcome()
    setNegotiationModalOpen(true)
  }
  const openShowNewsDigestModal = async () => {
    const fetchNewsDigest = async () => {
      const negColRef = collection(db, 'newsDigest')
      const q = query(negColRef, where('gameId', '==', gameId))
      const newsDigestSnap = await getDocs(q)
      const sortedOutcomes = newsDigestSnap.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .sort((a, b) => a.createdAt - b.createdAt)

      setRecentNewsDigests(sortedOutcomes)
    }
    await fetchNewsDigest()
    setShowNewsDigestModalOpen(true)
  }

  const closeNewsDigestModal = () => {
    setIsLocked(false)
    setNewsDigestModalOpen(false)
  }

  const generateNewsDigest = async () => {
    setIsFetching(true)
    const apiRequestBody = {
      gameId,
      type: 'digest'
    }

    try {
      const response = await fetch('https://peace-llm-api-esryy6i7aq-lz.a.run.app/', {
        method: 'POST', // Specifying the method as POST
        headers: {
          'Content-Type': 'application/json' // Setting the content type header
        },
        body: JSON.stringify(apiRequestBody) // Converting the data to a JSON string for the request body
      })

      if (!response.ok) { // Checking if the status code is not 200
        setIsFetching(false)
        throw new Error('Request failed with status ' + response.status)
      }
      console.log(response)
      openNewsDigestModal()
      setIsFetching(false) // This function is called upon successful response
    } catch (error) {
      console.error('Error:', error)
      alert('There has been an errror generating the news digest. Please try again in a few seconds')
      setIsFetching(false)
    }
  }

  return (
    <Container sx={pageStyle.page}>
      <Box sx={ matches ? { display: 'none' } : pageStyle.infoContainer}>
        <Typography variant='h5'>
          Info Panel
        </Typography>
        <Divider></Divider>
        <Typography variant='body'>
          Name of the Game: {gameObj.name}
        </Typography>
        <Typography variant='body'>
          Number of Players: {uniquePlayers.length }
        </Typography>
        <Typography variant='h6'>
          Players:
        </Typography>
        <Divider></Divider>
        <List>
          {uniquePlayers.map((player, index) => <ListItemText key={index} primary={player}></ListItemText>)}
        </List>
        <Divider sx={{ mt: '5px', mb: '5px' }}></Divider>
        <Button variant='contained' disabled={isFetching} onClick={generateNewsDigest}>{isFetching ? <CircularProgress /> : 'Generate News Digest' }</Button>
        <Divider sx={{ mt: '5px', mb: '5px' }} ></Divider>
        <Button sx={{ mt: '5px', mb: '5px' }} variant='contained' disabled={isFetching} onClick={openShowNewsDigestModal}>{isFetching ? <CircularProgress /> : 'Show all News Digests' }</Button>
        <Button sx={{ mt: '5px', mb: '5px' }}variant='contained' disabled={isFetching} onClick={openNegotiationModal}>{isFetching ? <CircularProgress /> : 'Show Negotiation Outcomes' }</Button>
      </Box>
      <Box sx={pageStyle.messagesContainer }>
      <List sx={{ m: '0px 3px 0px 3px', overflow: 'auto' }}>
              {messages.map((message, index) => <ChatMessage key={index} uniqueId={index} content={message.content} role={message.role} playerName={message.playerName} secondaryText={message.playerName} messageType={message.type}/>)}
              <div ref={ messagesEndRef }></div>
            </List>
      </Box>
    <BasicModal isOpen={isNewsDigestModalOpen} handleClose={() => { setNewsDigestModalOpen(false) } } title={'News Digest'}
      footerChildren={ <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
      <Button variant='contained' onClick={() => { isLocked ? closeNewsDigestModal() : lockUserScreens() } }>{isLocked ? 'Close Window' : 'Display To Users'}</Button>
      </Box>}>
        <Box sx={{ mb: '10px' }}>
        <Typography variant='body' sx={{ mb: 3, whiteSpace: 'pre-wrap' }}>
            {newsDigest}
        </Typography>
        </Box>
    </BasicModal>
    <BasicModal isOpen={isShowNewsDigestModalOpen} handleClose={() => { setShowNewsDigestModalOpen(false) } } title={'Recent News Digests'} >
    {recentNewsDigests.length > 0
      ? (
          recentNewsDigests.map((recentNewsDigest, index) => (
    <Box key={index}>
      <Typography variant='h6' sx={{ mb: 3, whiteSpace: 'pre-wrap' }}>
        NewsDigest {index + 1} - created at: {recentNewsDigest.createdAt.toDate().toLocaleTimeString()}
      </Typography>
      <Typography variant='body1' sx={{ mb: 3, whiteSpace: 'pre-wrap' }}>
        {recentNewsDigest.content}
      </Typography>
      <Divider />
    </Box>
          ))
        )
      : (
  <Typography variant="subtitle1" sx={{ mt: 2 }}>
    There has been no News Digest generated yet.
  </Typography>
        )}
    </BasicModal>
    <BasicModal isOpen={isNegotiationModalOpen} handleClose={() => { setNegotiationModalOpen(false) } } title={'Negotiation Outcomes'} >
    {negotiationOutcomes.length > 0
      ? (
          negotiationOutcomes.map((negotiationOutcome, index) => (
    <Box key={index}>
      <Typography variant='h6' sx={{ mb: 3, whiteSpace: 'pre-wrap' }}>
        Negotiation Outcome {index + 1} - created at: {negotiationOutcome.createdAt.toDate().toLocaleTimeString()}
      </Typography>
      <Typography variant='body1' sx={{ mb: 3, whiteSpace: 'pre-wrap' }}>
        {negotiationOutcome.content}
      </Typography>
      <Divider />
    </Box>
          ))
        )
      : (
  <Typography variant="subtitle1" sx={{ mt: 2 }}>
    No negotiation outcomes available at this time.
  </Typography>
        )}
    </BasicModal>
    </Container>
  )
}

export default AdminPage
