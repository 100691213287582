// React core and hooks
import React, { useRef, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
// Firebase Imports
import { db, auth } from '../firebase'
import { collection, query, where, serverTimestamp, onSnapshot, doc, orderBy, limit } from 'firebase/firestore'
// MUI Components and Icons
import { Container, Box, List, TextField, InputAdornment, IconButton, useTheme, useMediaQuery, Grid, Divider, Typography, Alert, Button } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
// Custom components
import ChatMessage from '../components/ChatMessage'
import ToggleButtons from '../components/ToggleButtons'
import DialogSlide from '../components/DialogSlide'
import BasicModal from '../components/modals/BasicModal'
import UnfoldableFeedListItem from '../components/UnfoldableFeedListItem'

const WELCOME_MESSAGE = 'Welcome to the Peace Game. In order to interact with the AI just write your prompt into the input field. There are two types of interactions that can be executed: Actions and Questions. It is always possible to ask questions. If you want to perform an action in the game world, select the right Button with the Speaker Icon. Consider your plans carefully, you will only be able to make an action every 2min. Get started by asking: "Who am I?"'
const ACTION_TIMER = 120

function ChatPage ({ gameRef, playerName, playerFaction, setIsPlaying }) {
  // Themeing
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTabletorMobile = useMediaQuery(theme.breakpoints.down('md'))
  const pageStyle = {
    content: { height: isMobile ? 'calc(100vh - 56px)' : 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', overflow: 'hidden' },
    messagesContainer: { maxWidth: '820px', m: 'auto', height: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flexGrow: 1, flexShrink: 1, overflow: 'auto' },
    newsFeedContainer: { marginTop: '2px', backgroundColor: '#F7F9F9', display: isTabletorMobile ? 'none' : 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' },
    newsFeedList: { overflow: 'auto' },
    messageList: { m: '0px 3px 0px 3px', overflow: 'auto' },
    inputContainer: { maxWidth: '820px', width: '100%', m: 'auto', display: 'flex', alignItems: 'center', flexGrow: 0, flexShrink: 0, mt: 1 },
    textField: { mr: '5px', mb: '5px' }
  }
  // State
  const gameId = gameRef
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [routeType, setRouteType] = useState('')
  const [lastRouteType, setLastRouteType] = useState('')
  const [countdownActive, setCountdownActive] = useState(false)
  const [notificationsCount, setNotificationsCount] = useState(0)
  const [news, setNews] = useState([])
  const [isLocked, setIsLocked] = useState(false)
  const [newsDigest, setNewsDigest] = useState('')
  const [openItemId, setOpenItemId] = useState(null)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const messagesEndRef = useRef()

  useEffect(() => {
  // Listening to messages collection
    const messagesQuery = query(
      collection(db, 'messages'),
      where('userId', '==', auth.currentUser.uid),
      where('gameId', '==', gameId)
    )
    const messagesUnsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
      const messagesDocs = []
      querySnapshot.forEach((doc) => {
        messagesDocs.push({
          ...doc.data()
        })
      })
      messagesDocs.sort((a, b) => a.createdAt - b.createdAt)
      setMessages(messagesDocs)
      if (messagesDocs.slice(-1)[0]?.role === 'assistant') {
        setIsTyping(false)
      }
    })

    // Listening to notifications collection
    const notificationsQuery = query(
      collection(db, 'news'),
      where('gameId', '==', gameId)
    )
    const newsUnsubcribe = onSnapshot(notificationsQuery, (querySnapshot) => {
      const newsDocs = []
      querySnapshot.forEach((doc) => {
        newsDocs.push({
          ...doc.data()
        })
      })
      newsDocs.sort((a, b) => b.createdAt - a.createdAt)
      setNotificationsCount(newsDocs.length)
      setNews(newsDocs)
    })

    // Listening to game state updates
    const gameRef = doc(db, 'games', gameId)
    const gameUnsubcribe = onSnapshot(gameRef, (doc) => {
      if (doc.exists()) {
        if (doc.data().isLocked === true) {
          setIsLocked(doc.data().isLocked)
        }
        if (doc.data().active === false) {
          setIsPlaying(false)
        }
      } else {
        // Handle the case where the document does not exist
        console.error('No such document!')
      }
    }, (err) => {
      console.error('Error listening to document:', err)
    })

    const newsDigestQuery = query(
      collection(db, 'newsDigest'),
      where('gameId', '==', gameId),
      orderBy('createdAt', 'desc'),
      limit(1)
    )
    const newsDigestUnsubscribe = onSnapshot(newsDigestQuery, (snapshot) => {
      // Assuming there is at least one document, otherwise handle accordingly
      if (!snapshot.empty) {
        setNewsDigest(snapshot.docs[0].data().content)
      }
    }, (error) => {
      // Handle any errors
      console.error('Error listening to the most recent document:', error)
    })
    // Cleanup function to unsubscribe from both collections
    return () => {
      messagesUnsubscribe()
      newsUnsubcribe()
      gameUnsubcribe()
      newsDigestUnsubscribe()
    }
  }, [])

  // scroll to bottom
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant' })
  }, [messages])

  // TODO: Check all 3 functions and Refactor
  async function processMessageToAPI (chatMessage, playerName, gameId, type, userId) {
    let url
    if (type === 'question') {
      url = 'https://queenbee-esryy6i7aq-lz.a.run.app'
    } else if (type === 'action') {
      url = 'https://peace-llm-api-esryy6i7aq-lz.a.run.app/'
    } else { console.error('Wrong Type provided.') }

    try {
      setIsTyping(true)
      const apiRequestBody = {
        name: playerName,
        content: chatMessage,
        gameId,
        type,
        userId
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(apiRequestBody)
      })
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      // assumes i get something back
      const data = await response.json()

      if (type === 'action') {
        setCountdownActive(true)
        setRouteType('question')
      }

      return data
    } catch (error) {
      console.error('Error in processMessageToAPI: ', error)
      setIsFailed(true)
      setIsTyping(false)
    }
  }

  const resendRequest = async () => {
    const lastMessage = messages.slice(-1)
    setIsFailed(false)
    await processMessageToAPI(lastMessage.content, lastMessage.playerName, gameId, lastRouteType, auth.currentUser.uid)
  }

  const handleSendMessage = async (e) => {
    e?.preventDefault()
    setIsFailed(false)
    if (routeType !== 'question' && routeType !== 'action') {
      setIsTooltipOpen(true)
      console.error('No route selected')
      return
    }
    if (!newMessage.trim()) return

    try {
      const messageObject = {
        content: newMessage.trim(),
        createdAt: serverTimestamp(),
        userId: auth.currentUser.uid,
        name: playerName,
        role: 'user',
        type: routeType,
        gameId
      }

      setNewMessage('')
      // add message to local state
      setMessages(prevMessages => [...prevMessages, messageObject])
      // send message to API
      await processMessageToAPI(newMessage.trim(), playerName, gameId, routeType, auth.currentUser.uid)
      setLastRouteType(routeType)
      setRouteType('')
    } catch (error) {
      console.error('Error sending message:', error)
      alert('We experiencing high traffic at the moment. Please try again in a few seconds')
    }
  }
  // Functions for Toggle Buttons
  const handleSelection = (event, newSelection) => {
    if (newSelection !== null) {
      setRouteType(newSelection)
      setIsTooltipOpen(false)
    }
  }

  const handleCountdownEnd = () => {
    setCountdownActive(false)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault() // Prevent the default action to avoid a new line being created
      handleSendMessage()
    }
  }
  // NewsDigest Toggle
  const toggleOpen = (uniqueId) => {
    setOpenItemId(openItemId === uniqueId ? null : uniqueId)
  }

  return (
    <Grid container>
      <Grid item md={3} sx={ pageStyle.newsFeedContainer}>
          <Typography variant='h5' align='center'> News Feed </Typography>
          <Divider></Divider>
          <List sx={pageStyle.newsFeedList}>
            {news.map((news, index) => <UnfoldableFeedListItem key={index} uniqueId={index} headline={news.headline} content={news.content} createdAt={news.createdAt} isOpen={openItemId === index} toggleOpen={toggleOpen} />)}
          </List>
      </Grid>
      <Grid item xs={12} md={9} >
          {isTabletorMobile &&
          <DialogSlide numberOfNotifications={notificationsCount}>
            <List sx={pageStyle.newsFeedList}>
              {news.map((news, index) => <UnfoldableFeedListItem key={index} uniqueId={index} headline={news.headline} content={news.content} createdAt={news.createdAt} isOpen={openItemId === index} toggleOpen={toggleOpen} />)}
            </List>
          </DialogSlide>}
      <Container sx={ pageStyle.content }>
          <Box sx={ pageStyle.messagesContainer }>
              <List sx={pageStyle.messageList}>
              <ChatMessage key={-1} uniqueId={-1} content={WELCOME_MESSAGE} role={'assistent'} playerName={playerName} playerFaction={playerFaction} ></ChatMessage>
                {messages.map((message, index) =>
                 <ChatMessage key={index} uniqueId={index} content={message.content} role={message.role} playerName={message.email} playerFaction={playerFaction} p4pComment={message.p4pComment} messageType={message.type} />
                )}
                {isTyping && <ChatMessage key={-2} uniqueId={-2} content={'AI is typing...'} role={'assistent'} playerName={playerName} playerFaction={playerFaction} ></ChatMessage>}
                {isFailed && <><Alert severity="warning" action={<Button color="inherit" onClick={resendRequest} size="small">RESEND</Button>}>Something went wrong. Please try again in a few seconds.</Alert> </>}
                <div ref={ messagesEndRef }></div>
              </List>
          </Box>
          <Box sx={ pageStyle.inputContainer }>
              <TextField
              fullWidth
              variant="outlined"
              label={(routeType === 'question') ? 'Ask me a Question' : ((routeType === 'action') ? 'Execute an Action' : 'Select Question or Action') }
              value={newMessage}
              disabled={isTyping}
              onChange={(e) => { setNewMessage(e.target.value) }}
              onKeyDown={handleKeyPress}
              multiline
              maxRows={3}
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handleSendMessage}>
                        <ArrowUpwardIcon />
                      </IconButton>
                    </InputAdornment>
                )
              }}
              sx={pageStyle.textField}
              />
                <ToggleButtons selected={routeType} countdownActive={countdownActive} actionTimer={ACTION_TIMER} handleSelection={handleSelection} handleCountdownEnd={handleCountdownEnd} isTooltipOpen={isTooltipOpen}></ToggleButtons>
          </Box>
      </Container>
      </Grid>
    <BasicModal isOpen={isLocked} title={'Game Paused for News Digest'} handleClose={() => { setIsLocked(false) }} >
      <Box sx={{ mb: '10px' }}>
        <Typography variant='body' sx={{ mb: 3, whiteSpace: 'pre-wrap' }}>
            {newsDigest}
        </Typography>
      </Box>
    </BasicModal>
    </Grid>
  )
}

ChatPage.propTypes = {
  gameRef: PropTypes.string.isRequired,
  playerName: PropTypes.string.isRequired,
  playerFaction: PropTypes.string.isRequired,
  setIsPlaying: PropTypes.func.isRequired
}

export default ChatPage
