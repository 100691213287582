import React, { useEffect, useState } from 'react'
import { Container, Box, useTheme, useMediaQuery, Typography, TextField, Divider, Button } from '@mui/material'
import { collection, query, orderBy, limit, getDocs, addDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.js'
import BasicModal from '../components/modals/BasicModal'
import TextInput from '../components/TextInput'

function UpdatePrompts () {
  // Themeing
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const pageStyle = {
    page: { height: matches ? 'calc(100vh - 56px)' : 'calc(100vh - 64px)' },
    textContainer: { m: 'auto', height: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' },
    textBox: { height: '33%', flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto', justifyContent: 'flex-end' },
    inputContainer: { m: 'auto', display: 'flex', height: '10%', justifyContent: 'flex-end', alignItems: 'center' }
  }
  // State
  const [basePrompt, setBasePrompt] = useState('')
  const [authorPrompt, setAuthorPrompt] = useState('')
  const [editorPrompt, setEditorPrompt] = useState('')
  const [isCreatePromptModalOpen, setIsCreatePromptModalOpen] = useState(false)

  useEffect(() => {
    const fetchMostRecentPrompt = async () => {
      try {
        const q = query(collection(db, 'prompts'), orderBy('createdAt', 'desc'), limit(1))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
          // Extract the data from the most recent document
          const data = doc.data()
          setBasePrompt(data.base)
          setAuthorPrompt(data.author)
          setEditorPrompt(data.editor)
        })
      } catch (error) {
        console.error('Error fetching most recent prompt:', error)
      }
    }
    fetchMostRecentPrompt()
  }, [])

  const handleUpdate = () => {
    setIsCreatePromptModalOpen(true)
  }

  const createNewPrompt = async (input) => {
    try {
      await addDoc(collection(db, 'prompts'), {
        base: basePrompt,
        author: authorPrompt,
        editor: editorPrompt,
        tag: input,
        createdAt: serverTimestamp()
      })
      setIsCreatePromptModalOpen(false)
    } catch (err) { console.error(err) }
  }

  return (
    <Container sx={ pageStyle.page }>
    <Box sx={ pageStyle.textContainer }>
        <Box sx={ pageStyle.textBox }>
        <Typography variant='h6'> Base-Prompt </Typography>
        <TextField
          multiline
          rows={5}
          fullWidth
          value={basePrompt}
          onChange={(e) => {
            setBasePrompt(e.target.value)
          }}
        > </TextField>
        <Divider></Divider>
        </Box>
        <Box sx={ pageStyle.textBox } >
        <Typography variant='h6'> Author-Prompt </Typography>
        <TextField
          multiline
          rows={5}
          fullWidth
          value={authorPrompt}
          onChange={(e) => {
            setAuthorPrompt(e.target.value)
          }}
        ></TextField>
        <Divider></Divider>
        </Box>
        <Box sx={ pageStyle.textBox }>
        <Typography variant='h6'> Editor-Prompt </Typography>
        <TextField
          multiline
          rows={5}
          fullWidth
          value={editorPrompt}
          onChange={(e) => {
            setEditorPrompt(e.target.value)
          }}>
          </TextField>
        <Divider></Divider>
        </Box>
    </Box>
    <Box sx={ pageStyle.inputContainer }>
    <Button variant="contained" size="medium" onClick={handleUpdate}>
                    Update
                  </Button>
    </Box>
    <BasicModal isOpen={isCreatePromptModalOpen} handleClose={() => { setIsCreatePromptModalOpen(false) } } >
      <Box sx={{ mb: '10px' }}>
      <Typography variant='h6'>
          Create A New Prompt
      </Typography>
      <Typography variant='body' sx={{ mb: 3 }}>
          After creating a new prompt, this prompt will be used for every newly created game. Give a meaningful tag to the prompt you created to reuse it later.
      </Typography>
      </Box>
      <TextInput inputLabel={'tag'} buttonLabel={'Create New Prompt'} handleSubmit={createNewPrompt}/>
    </BasicModal>
</Container>
  )
}

export default UpdatePrompts
