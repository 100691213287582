import React, { useEffect, useState } from 'react'
import { Container, Box, useTheme, useMediaQuery, Typography, Divider, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import { collection, query, orderBy, getDocs } from 'firebase/firestore'
import { db } from '../firebase.js'

function ListPrompts () {
  // Themeing
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const pageStyle = {
    page: { height: matches ? 'calc(100vh - 56px)' : 'calc(100vh - 64px)' },
    inputContainer: { mt: '10px', display: 'flex', height: '10%', justifyContent: 'flex-end', alignItems: 'center' },
    textContainer: { m: 'auto', height: '90%', display: 'flex', flexDirection: 'column', overflow: 'scroll' },
    textBox: { display: 'flex', flexDirection: 'column' }
  }
  // State
  const [prompts, setPrompts] = useState([])
  // const [selectedPrompt, setSelectedPrompt] = useState({})
  const [basePrompt, setBasePrompt] = useState('')
  const [authorPrompt, setAuthorPrompt] = useState('')
  const [editorPrompt, setEditorPrompt] = useState('')
  const [promptTag, setPromptTag] = useState('')

  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const q = query(collection(db, 'prompts'), orderBy('createdAt', 'asc'))
        const querySnapshot = await getDocs(q)
        const promptDocs = []
        querySnapshot.forEach((doc) => {
          promptDocs.push({
            ...doc.data()
          })
        })
        setPrompts(promptDocs)
      } catch (error) {
        console.error('Error fetching most recent prompt:', error)
      }
    }
    fetchPrompts()
  }, [])

  const handleChange = (event) => {
    const selectedPrompt = prompts.find(prompt => prompt.tag === event.target.value)
    setBasePrompt(selectedPrompt.base)
    setAuthorPrompt(selectedPrompt.author)
    setEditorPrompt(selectedPrompt.editor)
    setPromptTag(selectedPrompt.tag)
  }

  return (
    <Container sx={ pageStyle.page }>
    <Box sx={ pageStyle.inputContainer}>
      <FormControl fullWidth>
        <InputLabel>Prompt-Tag</InputLabel>
        <Select
          value={promptTag}
          label= "Prompt-Tag"
          onChange={handleChange}
        >
          {prompts.map((prompt, index) => <MenuItem key={index} value={ prompt.tag }> {`Tag: "${prompt.tag}" --  createdAt: ${prompt.createdAt?.toDate().toLocaleTimeString()} - ${prompt.createdAt?.toDate().toLocaleDateString()}`} </MenuItem>)}
        </Select>
      </FormControl>
    </Box>
    <Box sx={ pageStyle.textContainer }>
        <Box sx={ pageStyle.textBox }>
        <Typography variant='h6'> Base-Prompt </Typography>
        <Typography variant='body'>{ basePrompt } </Typography>
        <Divider></Divider>
        </Box>
        <Box sx={ pageStyle.textBox } >
        <Typography variant='h6'> Author-Prompt </Typography>
        <Typography variant='body'> {authorPrompt} </Typography>
        <Divider></Divider>
        </Box>
        <Box sx={ pageStyle.textBox }>
        <Typography variant='h6'> Editor-Prompt </Typography>
        <Typography variant='body'> {editorPrompt} </Typography>
        <Divider></Divider>
        </Box>
    </Box>
    </Container>
  )
}

export default ListPrompts
