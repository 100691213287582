import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LinearProgress, Box } from '@mui/material'

const CountDown = ({ initialCount, onCountdownEnd }) => {
  const [count, setCount] = useState(initialCount)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (count <= 0) {
      onCountdownEnd()
      return
    }

    const intervalId = setInterval(() => {
      setCount((prevCount) => prevCount - 1)
    }, 1000)
    setProgress(((initialCount - count) / initialCount) * 100)
    return () => clearInterval(intervalId)
  }, [count, onCountdownEnd])

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  )
}

CountDown.propTypes = {
  initialCount: PropTypes.number.isRequired,
  onCountdownEnd: PropTypes.func.isRequired
}

export default CountDown
