import React, { useState } from 'react'
import { Box, Typography, TextField, Button } from '@mui/material'
import { PropTypes } from 'prop-types'

const NegotiationInput = ({ isSubmitting, handleSubmit }) => {
  const pageStyle = {
    textContainer: { },
    textBox: { },
    buttonBox: { mt: '5px', display: 'flex', height: '10%', justifyContent: 'flex-end', alignItems: 'center' }
  }
  const [input, setInput] = useState('')

  const handleUpdate = () => {
    handleSubmit(input)
    setInput('')
  }

  return (
    <Box sx={pageStyle.textContainer}>
      <Box sx={pageStyle.textBox}>
          <Typography variant='h6'> Input here </Typography>
          <TextField
              multiline
              rows={5}
              fullWidth
              value={input}
              onChange={(e) => {
                setInput(e.target.value)
              }}
              />
      </Box>
      <Box sx={ pageStyle.buttonBox }>
      <Button disabled={isSubmitting} variant="contained" size="medium" onClick={handleUpdate}>
                      Publish
                    </Button>
      </Box>
    </Box>
  )
}

NegotiationInput.propTypes = {
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool
}

export default NegotiationInput
