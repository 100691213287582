// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyA-e_HLukTyEn-oqaoHrE3rd7iAy9Y6QTU',
  authDomain: 'peacegame-e8254.firebaseapp.com',
  projectId: 'peacegame-e8254',
  storageBucket: 'peacegame-e8254.appspot.com',
  messagingSenderId: '55236066410',
  appId: '1:55236066410:web:6d3083cc3ca33c8cb29e13'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const functions = getFunctions(app)

export { auth, db, functions }
