import React from 'react'
import { Typography, Button, Container } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

function NotFoundPage () {
  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5" gutterBottom>
        There is nothing here.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Do you want to go back to the Peace Game?
      </Typography>
      <Button component={RouterLink} to="/" color="primary">Go back</Button>
    </Container>
  )
}

export default NotFoundPage
