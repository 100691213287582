import React from 'react'
import PropTypes from 'prop-types'
// MUI Components
import { ListItem, ListItemText, ListItemAvatar, Divider, Avatar, Alert, AlertTitle } from '@mui/material'
// custom imports
import sikosianLogo from '..//assets/images/Sikosia_logo_circ.png'
import i4sLogo from '..//assets/images/I4S_logo_circ.png'
import p4pLogo from '..//assets/images/P4P_logo_circ.png'
import menuriLogo from '..//assets/images/Menuri_logo_circ.png'
import civilLogo from '..//assets/images/PeaceAid_logo.png'
import facilitatorLogo from '..//assets/images/Facilitator_logo_circ.png'
import arktikosLogo from '..//assets/images/Arktikos_logo_circ.png'
import crispLogo from '..//assets/images/crisp_logo_circ.jpeg'
import { playerToFactionMap } from '../constants/playerFactions'

const ChatMessage = ({ uniqueId, content, role, playerName, playerFaction, messageType, p4pComment, secondaryText }) => {
  // determine faction of player
  let faction
  if (playerFaction) {
    faction = playerFaction
  } else {
    faction = playerToFactionMap[playerName] || 'Default Faction'
  }
  // Determine user logo
  let userLogo
  switch (faction) {
    case 'ikti':
      userLogo = i4sLogo
      break
    case 'sikosia':
      userLogo = sikosianLogo
      break
    case 'civil':
      userLogo = civilLogo
      break
    case 'menuri':
      userLogo = menuriLogo
      break
    case 'arktikos':
      userLogo = arktikosLogo
      break
    case 'facilitator':
      userLogo = facilitatorLogo
      break
    default:
      userLogo = crispLogo
      break
  }

  let messageIndicator
  if (messageType === 'action' && role === 'user') {
    messageIndicator = 'Action: '
  } else if (messageType === 'question' && role === 'user') {
    messageIndicator = 'Question: '
  } else { messageIndicator = '' }

  // Determine avatar properties based on the role
  const avatarProps = role === 'user'
    ? {
        alt: 'User Logo',
        src: userLogo
      }
    : {
        alt: 'Peace Game Logo',
        src: p4pLogo
      }

  return (
    <div>
      <ListItem key={uniqueId}>
        <ListItemAvatar>
          <Avatar sx={{ objectFit: 'scale-down' }} {...avatarProps} />
        </ListItemAvatar>
        <ListItemText
          align="left"
          primary={ messageIndicator + content}
          secondary={ secondaryText }
          style={{ wordBreak: 'break-word', maxWidth: '100%' }}
        />
      </ListItem>
        { p4pComment && (role === 'assistant') && <Alert variant='outlined' icon={false} severity='info' sx={{ ml: '70px', mb: '5px' }}>
          <AlertTitle> Principles for Peace </AlertTitle>
          {p4pComment}
          </Alert>}
      <Divider />
    </div>
  )
}

ChatMessage.propTypes = {
  uniqueId: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  email: PropTypes.string,
  role: PropTypes.string,
  playerName: PropTypes.string,
  playerFaction: PropTypes.string,
  messageType: PropTypes.string,
  p4pComment: PropTypes.string,
  secondaryText: PropTypes.string
}

export default ChatMessage
