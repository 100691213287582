export const playerToFactionMap = {
  'Melvin Drexel': 'ikti',
  'Alia Haymat': 'ikti',
  'Henny Kamotali': 'ikti',
  'Johri Septuno': 'ikti',
  'Selama Segaia': 'ikti',
  'Nar Fikadu': 'sikosia',
  'Arvin Kaled': 'sikosia',
  'Esteban Tasti': 'sikosia',
  'Idara Veil': 'sikosia',
  'Loretta Imil': 'sikosia',
  'Alimonia Verdu': 'civil',
  'Jean-Pierre Dupont': 'civil',
  'Sarami Jo': 'civil',
  'Isel Fastu': 'civil',
  'Eli Swante': 'civil',
  'Reginald Dysfos': 'menuri',
  'James Damolif': 'menuri',
  'Layla Zarkov': 'menuri',
  'Rustam Farouk': 'menuri',
  'Marcus Jamson': 'menuri',
  'Sunan Taengon': 'facilitator',
  'Lina Patel': 'facilitator',
  'Chukwudi Kayode': 'facilitator',
  'Barbara Haber': 'facilitator',
  'Sofía Vasquez': 'facilitator',
  'Danielle Zindi': 'arktikos',
  'Henrik Voss ': 'arktikos',
  'Juan Pablo Arias': 'arktikos',
  'Kristina Eklund': 'arktikos',
  'Mikel Hona': 'arktikos'
}
