import React from 'react'
import Appbar from './Appbar'
import { useTheme, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'

const Layout = ({ playerName, playerFaction, playerStory, gameId, isPlaying, children }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const mainContentStyle = {
    paddingTop: isMobile ? '56px' : '64px'
  }

  return (

        <div id="layout">
        <Appbar playerName={playerName} playerFaction={playerFaction} playerStory={playerStory} gameId={gameId} isPlaying={isPlaying}/>
        <main style={mainContentStyle} id="page">
        {children}
        </main>
        </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  playerName: PropTypes.string.isRequired,
  playerFaction: PropTypes.string,
  playerStory: PropTypes.object,
  gameId: PropTypes.string,
  isPlaying: PropTypes.bool
}

export default Layout
