/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { AppBar, Toolbar, IconButton, Typography, Box, Menu, MenuItem, Avatar, List, Divider, useTheme, useMediaQuery } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { AccountCircle } from '@mui/icons-material'
import BasicModal from './modals/BasicModal.js'
import { auth, db } from '../firebase.js'
import { collection, getDocs, where, query, doc, updateDoc, addDoc, serverTimestamp } from 'firebase/firestore'
import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import GameListItem from './GameListItem.js'
import TextInput from '../components/TextInput.js'
import LoginModal from './modals/LoginModal.js'
import NegotiationInput from './NegotiationInput.js'
import sikosianLogo from '..//assets/images/Sikosia_logo_circ.png'
import i4sLogo from '..//assets/images/I4S_logo_circ.png'
import p4pLogo from '..//assets/images/P4P_logo.png'
import menuriLogo from '..//assets/images/Menuri_logo_circ.png'
import civilLogo from '..//assets/images/PeaceAid_logo.png'
import facilitatorLogo from '..//assets/images/Facilitator_logo_circ.png'
import arktikosLogo from '..//assets/images/Arktikos_logo_circ.png'
import crispLogo from '..//assets/images/crisp_logo_circ.jpeg'
import { PropTypes } from 'prop-types'

const Appbar = ({ playerName, playerFaction, playerStory, gameId, isPlaying }) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isCloseGameModalOpen, setIsCloseGameModalOpen] = useState(false)
  const [isCreateGameModalOpen, setIsCreateGameModalOpen] = useState(false)
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)
  const [isNegotiationModalOpen, setIsNegotiationModalOpen] = useState(false)
  const [isNegotiationSubmitting, setisNegotiationSubmitting] = useState(false)
  const [games, setGames] = useState([])
  const navigate = useNavigate()
  const isLoggedIn = !!auth.currentUser
  const playerBackground = playerStory?.background
  const playerInterests = playerStory?.interests
  const playerPosition = playerStory?.position
  const playerTheme = playerStory?.theme

  // Determine user logo
  let userLogo
  switch (playerFaction) {
    case 'ikti':
      userLogo = i4sLogo
      break
    case 'sikosia':
      userLogo = sikosianLogo
      break
    case 'civil':
      userLogo = civilLogo
      break
    case 'menuri':
      userLogo = menuriLogo
      break
    case 'arktikos':
      userLogo = arktikosLogo
      break
    case 'facilitator':
      userLogo = facilitatorLogo
      break
    default:
      userLogo = crispLogo
      break
  }

  function getDisplayStyle (isSomething) {
    return isSomething ? {} : { display: 'none' }
  }
  const isFacilitator = (playerFaction === 'facilitator')
  const isAdmin = !!auth.currentUser?.admin
  const showAdminComponentStyle = getDisplayStyle(isAdmin)
  const showNonAdminComponentStyle = getDisplayStyle(!isAdmin)
  const showUserComponent = getDisplayStyle(isLoggedIn)
  const showFacilitatorComponent = getDisplayStyle(isFacilitator && isPlaying)

  // opens and closes Menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  // Menu Button Logout
  const handleLogout = () => {
    signOut(auth).then(() => {
      localStorage.removeItem('readCount')
      navigate('/')
      console.log('Sign out successful')
    })
      .catch((error) => {
        console.error('Error signing out: ', error)
      })
  }
  // Profile Modal
  const openProfileModal = () => {
    setIsProfileModalOpen(true)
  }

  // Login Modal
  const openLoginModal = () => {
    setIsLoginModalOpen(true)
  }

  const openNegotiationModal = () => {
    setIsNegotiationModalOpen(true)
  }
  const updateNegotiations = async (input) => {
    try {
      setisNegotiationSubmitting(true)
      await addDoc(collection(db, 'negotiationOutcome'), {
        content: input,
        createdAt: serverTimestamp(),
        gameId
      })
      setisNegotiationSubmitting(false)
      alert('Negotiation have been updated sucessfully')
      closeModals()
    } catch (err) {
      setisNegotiationSubmitting(false)
      alert('There has been an error updating then negotiation outcome. Please try again')
      console.error(err)
    }
  }

  // Create Game Modal
  const openCreateGameModal = () => {
    setIsCreateGameModalOpen(true)
  }

  const createGame = async (input) => {
    try {
      await addDoc(collection(db, 'games'), {
        name: input,
        active: true,
        createdAt: serverTimestamp()
      })
      closeModals()
    } catch (err) { console.error(err) }
  }

  // Close Game Modal
  const openCloseGameModal = () => {
    // fetches active games that can be closed
    const fetchGames = async () => {
      const gamesColRef = collection(db, 'games')
      const q = query(gamesColRef, where('active', '==', true))
      const gamesSnap = await getDocs(q)
      setGames(gamesSnap.docs.map(doc => (
        {
          id: doc.id,
          ...doc.data()
        })
      ))
    }
    fetchGames()
    setIsCloseGameModalOpen(true)
    setAnchorEl(null)
  }

  const closeGame = async (selectedGameId) => {
    try {
      const docRef = doc(db, 'games', selectedGameId)
      await updateDoc(docRef, {
        active: false
      })
    } catch (error) {
      console.error('Error joining the game:', error)
    }
    setIsCloseGameModalOpen(false)
  }

  const closeModals = () => {
    setIsLoginModalOpen(false)
    setIsCloseGameModalOpen(false)
    setIsCreateGameModalOpen(false)
    setIsProfileModalOpen(false)
    setIsNegotiationModalOpen(false)
  }

  return (
    <>
    <Box>
      <AppBar position='fixed' elevation= {3}
      sx={{
        height: '64px',
        [theme.breakpoints.down('sm')]: {
          height: '56px'
        }
      }}>
        <Toolbar sx={{ display: 'flex', bgcolor: '#fefefe' }}>
          <Box sx={{ display: 'flex', flex: { xs: 0, md: 1 }, justifyContent: 'center' }}>
            <Box sx={{ mr: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconButton
                size="large"
                edge="start"
                color="primary"
                aria-label="menu"
                sx={ { ...showUserComponent, ...{ mr: 2 } } }
                onClick={handleClick}
                >
                <MenuIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <MenuItem sx={ showAdminComponentStyle } onClick={() => { navigate('/') }} >Lobby</MenuItem>
              <MenuItem sx={ showAdminComponentStyle } onClick={() => { navigate('/list') }} >List Prompts</MenuItem>
              <MenuItem sx={ showAdminComponentStyle } onClick={() => { navigate('/update') }} >Update Prompt</MenuItem>
              <MenuItem sx={ showAdminComponentStyle } onClick={openCreateGameModal} >Create Game</MenuItem>
              <MenuItem sx={ showAdminComponentStyle } onClick={openCloseGameModal} >Close Game</MenuItem>
              <MenuItem sx={ showFacilitatorComponent } onClick={openNegotiationModal} >Negotiation Outcomes</MenuItem>
              <MenuItem sx={ showNonAdminComponentStyle } onClick={openProfileModal}> Profile </MenuItem>
              <MenuItem onClick={handleLogout} >Logout</MenuItem>
            </Menu>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, flex: 1, justifyContent: 'center' }}>
            <Box sx={{ flex: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
              <img src={p4pLogo} alt='Peace Game Logo' style={{ width: '100px', height: '90%', objectFit: 'fill', display: 'block' }} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            { isLoggedIn
              ? <Box sx={ { display: 'flex', ml: 'auto', alignItems: 'center' } }>
                    <Typography variant="h6" color="black" sx={{ mr: '5px' }}>
                    {playerName}
                    </Typography>
                    <Avatar alt="User Logo" src={userLogo} />
                </Box>
              : <IconButton
                size='large'
                aria-label='Login Button'
                aria-haspopup="true"
                onClick={openLoginModal}f
                color='primary'
                sx={{ ml: 'auto' }}
                >
                  <AccountCircle />
                </IconButton>}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
    <LoginModal isOpen={isLoginModalOpen} handleClose={closeModals}>
    </LoginModal>
    <BasicModal isOpen={isCloseGameModalOpen} handleClose={closeModals} title={'Select Game To Close It'}>
      <List>
            {games.map((game) => (
              <>
              <GameListItem key={game.id} gameId={game.id} gameName={game.name} createdAt={game.createdAt} buttonLabel={'Close Game ❌'} handleSubmit={closeGame}></GameListItem>
              <Divider/>
              </>
            ))}
      </List>
    </BasicModal>
    <BasicModal isOpen={isCreateGameModalOpen} handleClose={closeModals} title={'Create a Game'} >
      <TextInput inputLabel={'Name'} buttonLabel={'Create Game'} handleSubmit={createGame}/>
    </BasicModal>
    <BasicModal isOpen={isProfileModalOpen} handleClose={closeModals} title={playerName}>
      <Typography variant='h6'>
          Background
      </Typography>
      <Divider></Divider>
      <Typography variant='body'>
            {playerBackground}
      </Typography>
      <Typography variant='h6'>
          Interests
      </Typography>
      <Divider></Divider>
      <Typography variant='body'>
          {playerInterests}
      </Typography>
      <Typography variant='h6'>
          Position towards Peace Agreement
      </Typography>
      <Divider></Divider>
      <Typography variant='body'>
          {playerPosition}
      </Typography>
      <Typography variant='h6'>
          Thematic Group
      </Typography>
      <Divider></Divider>
      <Typography variant='body'>
          {playerTheme}
      </Typography>
    </BasicModal>
    <BasicModal isOpen={isNegotiationModalOpen} handleClose={closeModals} title={'Publish Negotiation Outcome'} >
      <NegotiationInput isSubmitting={isNegotiationSubmitting} handleSubmit={updateNegotiations}></NegotiationInput>
    </BasicModal>
    </>

  )
}

Appbar.propTypes = {
  playerName: PropTypes.string,
  playerFaction: PropTypes.string,
  playerStory: PropTypes.object,
  gameId: PropTypes.string,
  isPlaying: PropTypes.bool
}

export default Appbar
