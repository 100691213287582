import React from 'react'
import { ListItem, Button, ListItemText, Divider, Typography, useTheme, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'

const GameListItem = ({ uniqueKey, gameId, gameName, createdAt, buttonLabel, handleSubmit }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const createdAtString = `created at: ${createdAt?.toDate().toLocaleTimeString()} - ${createdAt?.toDate().toLocaleDateString()}`
  const pageStyle = {
    listItemSecondary: matches ? null : createdAtString
  }

  const handleClick = async () => {
    handleSubmit(gameId)
  }

  return (
    <div>
      <ListItem key={uniqueKey}
                sx={{ margin: '5px', borderRadius: '5px', maxWidth: '98%' }}
                secondaryAction={
                  <Button variant="contained" size="medium" onClick={handleClick}>
                    {buttonLabel}
                  </Button>
                }
                >
                <ListItemText primary={ <><Typography variant='h5' > {gameName} </Typography></> } secondary={pageStyle.listItemSecondary}/>
              </ListItem>
              <Divider/>
    </div>
  )
}

GameListItem.propTypes = {
  uniqueKey: PropTypes.number.isRequired,
  gameId: PropTypes.string.isRequired,
  gameName: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  createdAt: PropTypes.object
}

export default GameListItem
