import React, { useState } from 'react'
import { Container, Typography, TextField, Button, Box, Modal } from '@mui/material'
import { auth } from '../../firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { PropTypes } from 'prop-types'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: { xs: '80%', sm: 400 },
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  p: 3
}

function LoginModal ({ isOpen, handleClose }) {
  const [error, setError] = useState('')
  if (!isOpen) return null

  const handleBackdropClick = (event) => {
    setError('')
    // Check if the click is on the backdrop itself, not its children
    if (event.target === event.currentTarget) {
      handleClose() // Call the onClose function which sets the state to close the modal
    }
  }

  const handleSubmit = (event) => {
    event?.preventDefault()
    const data = new FormData(event.target)
    signInWithEmailAndPassword(auth, data.get('email'), data.get('password'))
      .then(() => {
        console.log('Login Successful')
        setError('')
        handleClose()
      })
      .catch((error) => {
        setError('Invalid Login: Please enter a correct email and password')
        console.log(error.code)
        console.log(error.message)
      })
  }

  return (
      <Modal
        open={isOpen}
        onClose={handleBackdropClick}
      >
    <Box sx={style}>
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email-Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={!!error}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={!!error}
          helperText={error}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: '10px' }}
        >
          Sign In
        </Button>
      </form>
    </Container>
    </Box>
    </Modal>
  )
}

LoginModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default LoginModal
